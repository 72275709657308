import request from "./../common/api";

export function getReadyToPayGiftCard(outletId) {
  return request({
    url: `secure/r2/gift-card/readytopay-giftcard?outlet=${outletId}`,
    method: "get"
  });
}

export function addGiftCardToCart(id) {
  return request({
    url: `secure/r2/gift-card/addToCart/${id}`,
    method: "put"
  });
}

export function releaseGiftCard(payload) {
  return request({
    url: `secure/r2/gift-card/release`,
    method: "post",
    data: payload
  });
}

export function getGiftCardInvoice(payload) {
  const { outletId, searchText, currentPage, date } = payload;
  let query = `outletId=${outletId}`;
  searchText && (query += `&searchText=${searchText}`);
  currentPage && (query += `&currentPage=${currentPage}`);
  date && (query += `&date=${date}`);

  return request({
    url: `secure/r2/gift-card/invoice?${query}`,
    method: "get"
  });
}

export function removeGiftCard(id) {
  return request({
    url: `secure/r2/gift-card/${id}`,
    method: "delete"
  });
}

export function getGiftCardSoldByOutlet(payload) {
  return request({
    url: `secure/r1/gift-card/sold-by-user?date=${payload.date}&outletId=${payload.outletId}`,
    method: "get"
  });
}

export function getGiftCardOutletTransactions(payload) {
  return request({
    url: `secure/r1/gift-card/transactions?date=${payload.date}&outletId=${payload.outletId}`,
    method: "get"
  });
}
