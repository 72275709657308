<template>
  <div>
    <OutletSelect @change="onSelectOutlet"></OutletSelect>
    <KTPortlet title="Danh sách Gift Card" headSize="md">
      <template v-slot:toolbar>
        <router-link :to="{ name: 'gift-card-create' }">
          <i class="flaticon-add mr-2"></i>
          <span>Tạo mới</span>
        </router-link>
      </template>

      <template v-slot:body>
        <SearchInput
          pHolder="Nhập để tìm kiếm..."
          @search="onSearch"
        ></SearchInput>
        <div class="mt-3 table-gift-card">
          <b-table
            :items="giftCards"
            :fields="fields"
            responsive
            head-variant="light"
            :sticky-header="true"
          >
            <template v-slot:cell(code)="data">
              <div
                :class="
                  data.item.status === 0 || data.item.status === 1 ? 'ml-4' : ''
                "
                v-b-toggle="data.value"
                @click="data.toggleDetails"
              >
                <span class="icon mr-2 icon-arrow" v-if="data.item.status !== 0"
                  ><i
                    :class="
                      data.detailsShowing ? 'flaticon2-back' : 'flaticon2-down'
                    "
                  >
                  </i>
                </span>
                <span>{{ data.value }}</span>
              </div>
            </template>
            <template v-slot:cell(status)="data">
              <span>
                <b-badge
                  v-if="data.value === 0"
                  variant="info"
                  class="btn-status"
                  >Chưa bán</b-badge
                >
                <b-badge
                  v-else-if="data.value === 1"
                  variant="warning"
                  class="btn-status"
                  >Chờ thanh toán</b-badge
                >
                <b-badge
                  v-else-if="data.value === 2 && data.item.balance > 0"
                  variant="success"
                  class="btn-status"
                  >Đã Bán ({{ data.item.balance }})</b-badge
                >
                <b-badge
                  v-else-if="
                    data.value === 3 ||
                      (data.value === 2 && data.item.balance === 0)
                  "
                  variant="danger"
                  class="btn-status"
                  >Đã sử dụng hết</b-badge
                >
              </span>
            </template>

            <template v-slot:cell(seller)="data">{{
              data.value.name
            }}</template>
            <template v-slot:cell(customer)="data">{{
              data.value.name
            }}</template>

            <template v-slot:cell(detail)="data">
              <b-dropdown right size="sm" no-caret variant="none">
                <template v-slot:button-content>
                  <i class="flaticon2-indent-dots"></i>
                </template>

                <b-dropdown-item>
                  <div
                    class="w-100 align-items-center"
                    @click="addToCart(data.item)"
                    :disabled="data.item.status === 2"
                  >
                    Bỏ vào giỏ hàng
                  </div>
                </b-dropdown-item>
                <b-dropdown-item>
                  <router-link
                    :to="{
                      name: 'gift-card-edit',
                      params: { item: data.item }
                    }"
                  >
                    <div class="d-flex align-items-center p-0 edit-gift-card">
                      <span>Chỉnh sửa</span>
                    </div>
                  </router-link>
                </b-dropdown-item>
                <b-dropdown-item v-if="data.item.status === 0">
                  <div
                    class="w-100 align-items-center"
                    @click="deleteGiftCard(data.item)"
                  >
                    Xóa
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </template>

            <template v-slot:row-details="row">
              <b-card
                class="card-content"
                v-if="row.item.status !== 0 && row.item.status !== 1"
              >
                <div
                  class="card-text"
                  v-for="item of row.item.transactions"
                  :key="item._id"
                >
                  <span>Ngày sử dụng: </span>
                  <span
                    class="content ml-2"
                    @click="showDetailInvoice(item.invoiceId)"
                    >{{ formatDate(item.createdAt) }}</span
                  >
                </div>
                <div
                  class="card-text"
                  v-if="row.item.transactions.length === 0"
                >
                  <span>Chưa sử dụng lần nào.</span>
                </div>
              </b-card>
            </template>
          </b-table>

          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
            @input="onChangePage"
          ></b-pagination>
        </div>
      </template>
    </KTPortlet>
  </div>
</template>

<style lang="scss">
@import "./GiftCard.scss";
</style>

<script>
import KTPortlet from "@/components/Portlet.vue";
import OutletSelect from "@/components/outlet/Select.vue";
import SearchInput from "@/components/SearchInput.vue";
import { getCurrentDate } from "../../common/utils/getDate";
import { mapGetters, mapActions } from "vuex";
import axios from "@/common/api.js";
import Swal from "sweetalert2";
import { addGiftCardToCart, removeGiftCard } from "@/api/gift-card";
import Loader from "@/common/loader";
import moment from "moment";

export default {
  components: {
    OutletSelect,
    KTPortlet,
    SearchInput
  },
  data() {
    return {
      fields: [
        {
          key: "code",
          label: "Mã Gift Card",
          stickyColumn: true,
          variant: "light"
        },
        {
          key: "status",
          label: "Trạng thái"
        },
        {
          key: "price",
          label: "Giá trị"
        },
        {
          key: "purchasedDate",
          label: "Ngày bán"
        },
        {
          key: "seller",
          label: "Người bán"
        },
        {
          key: "customer",
          label: "Tên khách hàng"
        },
        {
          key: "detail",
          label: "Tuỳ chọn"
        }
      ],
      currentOutlet: "",
      giftCards: [],
      isGiftCard: true,
      currentPage: 1,
      perPage: 10,
      rows: 1
    };
  },
  created() {
    this.$store.dispatch("titleBreadcrumb", "Danh sách Gift Card");
  },
  methods: {
    ...mapActions(["addProduct"]),
    formatDate(timestamps) {
      return moment(timestamps).format("DD/MM/YYYY");
    },
    onSelectOutlet(event) {
      this.currentOutlet = event;
      this.getGiftCard();
    },
    onSearch($event) {
      let uri = `/secure/r2/gift-card?outletId=${this.currentOutlet}${
        $event ? "&searchText=" + $event : ""
      }`;
      axios.get(uri).then(res => {
        this.giftCards = res.data;
      });
    },
    addToCart(giftCard) {
      if (giftCard.status === 2) {
        Swal.fire({
          icon: "warning",
          title: "Gift Card đã được bán.",
          showConfirmButton: true,
          timer: 3000
        });
      } else {
        addGiftCardToCart(giftCard._id).then(res => {
          this.giftCards.splice(this.giftCards.indexOf(giftCard), 1);
          Swal.fire({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "Đã thêm sản phẩm vào giỏ hàng!",
            showConfirmButton: false,
            timer: 3000
          });
        });
      }
    },
    viewDetail(giftCardId) {
      console.log(giftCardId);
    },
    successAlert() {
      return Swal.fire({
        toast: true,
        position: "top-end",
        icon: "success",
        title: "Cập nhật thành công!",
        showConfirmButton: false,
        timer: 3000
      });
    },
    showDetailInvoice(data) {
      this.$router.push(`/invoice/detail/${data}`);
    },
    getGiftCard() {
      Loader.fire();
      axios
        .get(
          `/secure/r2/gift-card?outletId=${this.currentOutlet}&currentPage=${this.currentPage}`
        )
        .then(res => {
          this.giftCards = res.data.data;
          this.rows = res.data.rows;
          Loader.close();
        });
    },
    onChangePage(event) {
      this.currentPage = event;
      this.getGiftCard();
    },
    deleteGiftCard(giftCard) {
      Loader.fire();
      removeGiftCard(giftCard._id)
        .then(res => {
          return this.getGiftCard();
        })
        .then(() => {
          Swal.fire({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "Xóa thành công!",
            showConfirmButton: false,
            timer: 3000
          });
        })
        .catch(e => {
          return Swal.fire({
            icon: "error",
            title: e.errors,
            showConfirmButton: false,
            timer: 3000
          });
        })
        .finally(Loader.close);
    }
  }
};
</script>
